<template>
    <div class="container pt-16 px-2 ">
        <div v-if="globalArticles" class="m-auto border-2 rounded w-3/4 p-8">

            <h1 class="text-red text-lg mb-6">
                Success Criteria
            </h1>
        
            <ButtonTemplate :on_click="OpenNewWindow" :click_param="{ component:'articles', data:-1}" class="my-6 mr-2" type="button">Add New</ButtonTemplate>

            <TextInput class="w-full" type="text" label="Search" v-model="articleSearch" :required="false" ></TextInput>

            <div class="flex flex-wrap">
                <div v-for="(article, index) in getSortedArticles" :key="article.id">
                    <ButtonTemplate btn_style="light" :on_click="OpenNewWindow" :click_param="{ component:'articles', data:article}" class="my-6 mr-2 w-36 h-32 overflow-hidden" type="button">{{article.title}}</ButtonTemplate>
                </div>
            </div>
        </div>

        <div v-if="globalArticles" class="m-auto mt-8 border-2 rounded w-3/4 p-8">
        
            <h1 class="text-red text-lg mb-6">
                Techniques
            </h1>
        
            <ButtonTemplate :on_click="OpenNewWindow" :click_param="{ component:'techniques', data:-1}" class="my-6 mr-2" type="button">Add New</ButtonTemplate>

            <TextInput class="w-full" type="text" label="Search" v-model="techniqueSearch" :required="false" ></TextInput>

            <div class="flex flex-wrap">
                <div v-for="(technique, index) in getSortedTechniques" :key="technique.id">   
                    <ButtonTemplate btn_style="light" :on_click="OpenNewWindow" :click_param="{ component:'techniques', data:technique}" class="my-6 mr-2 w-36 h-32 overflow-hidden" type="button">{{technique.title}}</ButtonTemplate>
                </div>
            </div>
        </div>

        <div v-if="globalArticles" class="m-auto mt-8 border-2 rounded w-3/4 p-8">
        
            <h1 class="text-red text-lg mb-6">
                Recommendations
            </h1>
        
            <ButtonTemplate :on_click="OpenNewWindow" :click_param="{ component:'recommendations', data:-1}" class="my-6 mr-2" type="button">Add New</ButtonTemplate>

            <TextInput class="w-full" type="text" label="Search" v-model="recommendationSearch" :required="false" ></TextInput>

            <div class="flex flex-wrap">
                <div v-for="(recommendation, index) in getSortedRecommendations" :key="recommendation.id">   
                    <ButtonTemplate btn_style="light" :on_click="OpenNewWindow" :click_param="{ component:'recommendations', data:recommendation}" class="my-6 mr-2 w-36 h-32 overflow-hidden" type="button">{{recommendation.description}}</ButtonTemplate>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'SuccessCriteria',
    data: () => ({
        articles: [],
        sortedArticles: [],
        articleSearch: "",

        techniques: [],
        sortedTechniques: [],
        techniqueSearch: "",

        recommendations: [],
        sortedRecommendations: [],
        recommendationSearch: "",
    }),
    computed: {
        globalArticles() {
            return this.articles;
        },
        getSortedArticles() {
            return this.sortedArticles;
        },
        globalTechniques() {
            return this.techniques;
        },
        getSortedTechniques() {
            return this.sortedTechniques;
        },
        globalRecommendations() {
            return this.recommendations;
        },
        getSortedRecommendations() {
            return this.sortedRecommendations;
        },
    },
    props: [],
    methods: {
        OpenNewWindow(data) {

            var component;
            if ( data.component == "articles")
                component = 'SuccessCriteria';
            else if ( data.component == "techniques")
                component = 'Techniques';
            else
                component = 'Recommendations';

            this.$store.commit('modal/setContent',{
                title: 'Log in using this link',
                component: component,
                data: data.data,
                open: true,
            })
            return true;
        },

        Load()
        {
            this.$requests.get(`/globalArticles`,
            {
                success: (re) => {
                    this.articles = re.data.items;
                    this.sortedArticles = this.articles;
                    return true;
                },
            })
            this.$requests.get(`/globalTechniques`,
            {
                success: (re) => {
                    this.techniques = re.data.items;
                    this.sortedTechniques = this.techniques;
                    return true;
                },
            })
            this.$requests.get(`/globalRecommendations`,
            {
                success: (re) => {
                    this.recommendations = re.data.items;
                    this.sortedRecommendations = this.recommendations;
                    return true;
                },
            })

        }
    },
    watch: {
        '$store.state.modal.open': function() {
            this.Load();            
        },

        articleSearch(search) {
            if ( search != "" )
            {
                let newList = [];
                this.articles.forEach(function(article) {
                    if ( article.title.includes(search) || article.number.includes(search) || article.description.includes(search) || article.level.includes(search) )
                    {
                        newList.push(article);
                    }
                })
                this.sortedArticles = newList;
            }
            else
                this.sortedArticles = this.articles;
            return search;
        },

        techniqueSearch(search) {
            if ( search != "" )
            {
                let newList = [];
                this.techniques.forEach(function(technique) {
                    if ( technique.title.includes(search) || technique.number.includes(search) || technique.description.includes(search) )
                    {
                        newList.push(technique);
                    }
                })
                this.sortedTechniques = newList;
            }
            else
                this.sortedTechniques = this.techniques;
            return search;
        },

        recommendationSearch(search) {
            if ( search != "" )
            {
                let newList = [];
                this.recommendations.forEach(function(recommendation) {
                    if ( recommendation.description.includes(search) )
                    {
                        newList.push(recommendation);
                    }
                })
                this.sortedRecommendations = newList;
            }
            else
                this.sortedRecommendations = this.recommendations;
            return search;
        }
    },
    created() {
        this.Load();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
