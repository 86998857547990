<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Settings
        </h1>
        <div class="">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/settings" :formdata="formData">
                <TextInput type="text" label="Name" v-model="settings.name" :required="false" ></TextInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingsView',
    mixins: [],
    data: () => ({
        settings: {},
    }),
    computed: {
        formData() {
            return {
                'settings':this.settings,
            }
        },
    },
    props: [],
    methods: {
        formResponse(re) {
            console.log(re)
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
